
@font-face {
    font-family: 'Campton';
    src: url('../fonts/campton.otf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('../fonts/campton-bold.otf') format('truetype');
    font-weight: bold;
}

html {
    // background-image: url('../images/background.png');
    // @media(max-width: 900px) {
    //     background-image: url('../images/background-mobile.png');
    // };
    // background-repeat: no-repeat;
    // background-size: auto 100vh;
    // background-position-y: top;
    // background-position-x: right;
    // background-color: #213147; 


    background: url('../images/background.png') no-repeat center center fixed; 
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    font-family: 'Campton';
    margin: 0;
}

.shadow {
    box-shadow: -3px 6px 6px 0px rgb(0 0 0 / 10%);
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}
@use "./global";

.sections-container {
    display: flex;
    
    .center-elements {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media(max-width: 900px) {
            top: 35%;
            img {
                width: 50vw;       
            }
        }
    }

    .bottom-elements {
        @extend .flex-row;
        position: absolute;
        bottom: 4vw;
        left: 50%;
        transform: translate(-50%, -50%);

        h2 {
            text-shadow: 1px 1px 2px black;
            text-align: center;
            color: white;
            font-weight: normal;
            font-size: 20px;
            letter-spacing: 5px;
            margin: 0;

            @media(max-width: 900px) {
                font-size: 15px;
                letter-spacing: 2px;
            }
        }
    }
}